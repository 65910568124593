import router from '@/router'
import env from '@/config/env'

const config = {
  dongleId: '',
  userLanguage: env.userLanguage
}

const populateConfigFromQueryParams = async () => {
  await router.isReady()

  const { dongleId, lang } = router.currentRoute.value.query

  if (typeof dongleId === 'string') config.dongleId = dongleId
  if (typeof lang === 'string') config.userLanguage = lang
}

const handleInitialConfig = async () => {
  await populateConfigFromQueryParams()
}

export { handleInitialConfig }
export default config
