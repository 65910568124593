import { ref } from 'vue'
import { defineStore } from 'pinia'

const useModalStore = defineStore('modal', () => {
  const modalState = ref({
    loginRequired: false
  })

  return {
    modalState
  }
})

export default useModalStore
