import { ref } from 'vue'
import { defineStore } from 'pinia'
import { isAxiosError } from 'axios'
import { type ToastNotifications, type NotificationVariant } from '@nsftx/seven-components'

import useTranslationsStore from '@/stores/translations'

const useNotificationsStore = defineStore('notifications', () => {
  const translationsStore = useTranslationsStore()

  const notifications = ref<ToastNotifications>([])

  const addNotification = (
    message: string,
    variant: NotificationVariant = 'error',
    uuid = crypto.randomUUID()
  ) => {
    notifications.value.push({
      messages: [message],
      variant,
      uuid
    })
  }

  const handleErrorNotification = (err: unknown) => {
    const message =
      isAxiosError(err) && err.response?.data?.message
        ? err.response.data.message
        : translationsStore.t('default_error_message')

    addNotification(message)
  }

  return {
    notifications,
    addNotification,
    handleErrorNotification
  }
})

export default useNotificationsStore
