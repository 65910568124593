import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore, storeToRefs } from 'pinia'
import { Client } from '@stomp/stompjs'

import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useAuthStore from '@/stores/auth'
import useAuth from '@/composables/useAuth'
import { ROUTES } from '@/utilities/constants'
import env from '@/config/env'
import { STOMP_CLIENT_MESSAGES, type StompMessageData } from '@/types/stores/stompClient'

const useStompClient = defineStore('stomp-client', () => {
  const router = useRouter()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const authStore = useAuthStore()
  const auth = useAuth()

  const { qrCodeLinkData, authData, isCashoutLoading, isAuthenticated, isTerminalOutOfOrder } =
    storeToRefs(authStore)

  const { addNotification } = notificationsStore
  const { t } = translationsStore

  let client: Client | null = null

  const isClientActivated = ref(false)
  const currentSession = ref<string | null>(null)

  const sessionId = computed(() => authData.value?.sessionId || qrCodeLinkData.value?.sessionId)

  const handleStompMessage = async (type: STOMP_CLIENT_MESSAGES) => {
    switch (type) {
      case STOMP_CLIENT_MESSAGES.SESSION_INITIATED:
        await router.push({
          name: ROUTES.OTP,
          params: {
            sessionId: sessionId.value
          }
        })
        break

      /**
       * TERMINAL_DEACTIVATED and SESSION_TERMINATED are always sent at the same time
       */
      case STOMP_CLIENT_MESSAGES.TERMINAL_DEACTIVATED:
        isTerminalOutOfOrder.value = true
        deactivate()
        await router.push({ name: ROUTES.QR_CODE })
        break

      /**
       * When doing /cashout api from the site, sometimes it can occur that SESSION_TERMINATED comes during cashout api,
       * and sometimes it can occur that SESSION_TERMINATED comes after cashout api.
       */
      case STOMP_CLIENT_MESSAGES.SESSION_TERMINATED:
        if (isCashoutLoading.value || !isAuthenticated.value) return

        auth.handlePostLogoutActions()

        await router.push({ name: ROUTES.QR_CODE })
        break

      case STOMP_CLIENT_MESSAGES.SUCCESSFUL_DEPOSIT:
        addNotification(t('successful_deposit_notification_message'), 'success')
        break
    }
  }

  const subscribeSession = (sessionId: string) => {
    currentSession.value = sessionId

    client?.subscribe(`/topic/session/${sessionId}`, async (message) => {
      const body: StompMessageData = JSON.parse(message.body)
      await handleStompMessage(body.type)
    })
  }

  const unsubscribeSession = (sessionId: string) => {
    client?.unsubscribe(`/topic/session/${sessionId}`)
  }

  const deactivate = () => {
    client?.deactivate()
  }

  const init = () => {
    client = new Client({
      brokerURL: env.ssbtStompUrl,
      reconnectDelay: 3000,
      debug: function (str) {
        console.log(str)
      }
    })

    client.onConnect = () => {
      if (sessionId.value) {
        subscribeSession(sessionId.value)
      }
      isClientActivated.value = true
    }

    client.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers['message'])
      console.error('Additional details: ' + frame.body)
      console.error('Error', frame)
    }

    client.activate()
  }

  return {
    currentSession,
    isClientActivated,
    init,
    deactivate,
    subscribeSession,
    unsubscribeSession
  }
})

export default useStompClient
