import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { storeToRefs } from 'pinia'

import useAuthStore from '@/stores/auth'
import { HEADERS } from '@/utilities/constants'
import env from '@/config/env'

function createAxiosInstance(
  options: AxiosRequestConfig,
  additionalHeaders?: HEADERS[]
): AxiosInstance {
  const instance = axios.create(options)

  instance.interceptors.request.use((config) => {
    const authStore = useAuthStore()

    const { authData } = storeToRefs(authStore)

    const headersMapper: Record<HEADERS, string> = {
      [HEADERS.CompanyUUID]: env.tenant.uuid,
      [HEADERS.Authorization]: `Bearer ${authData.value?.jwt}`,
      [HEADERS.RequestID]: crypto.randomUUID()
    }

    config.headers[HEADERS.RequestID] = headersMapper[HEADERS.RequestID]

    additionalHeaders?.forEach((header) => {
      config.headers[header] = headersMapper[header]
    })

    return config
  })

  return instance
}

export { createAxiosInstance }
