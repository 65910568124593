const {
  MODE,
  VITE_APP_TENANT_NAME,
  VITE_APP_TENANT_UUID,
  VITE_APP_TENANT_COUNTRY,
  VITE_APP_TENANT_CURRENCY,
  VITE_APP_USER_LANGUAGE,
  VITE_APP_DEFAULT_LANGUAGE_ISO1,
  VITE_APP_DEFAULT_LANGUAGE_ISO2,
  VITE_APP_BASE_URL,
  VITE_APP_API_BASEURL,
  VITE_APP_SEVEN_PLATFORM_MONOLITH,
  VITE_APP_CASINO_APP_URL,
  VITE_APP_FRASIER_API_BASEURL,
  VITE_APP_FRASIER_DOMAIN,
  VITE_APP_SENTRY_DSN,
  VITE_APP_SSBT_STOMP_URL
} = import.meta.env

export default {
  isDevMode: MODE === 'staging',
  tenant: {
    name: VITE_APP_TENANT_NAME,
    uuid: VITE_APP_TENANT_UUID,
    country: VITE_APP_TENANT_COUNTRY,
    currency: VITE_APP_TENANT_CURRENCY
  },
  userLanguage: VITE_APP_USER_LANGUAGE,
  languageISO1: VITE_APP_DEFAULT_LANGUAGE_ISO1,
  languageISO2: VITE_APP_DEFAULT_LANGUAGE_ISO2,
  baseUrl: VITE_APP_BASE_URL,
  apiBaseUrl: VITE_APP_API_BASEURL,
  sevenPlatformMonolith: VITE_APP_SEVEN_PLATFORM_MONOLITH,
  casinoAppUrl: VITE_APP_CASINO_APP_URL,
  frasierApiBaseUrl: VITE_APP_FRASIER_API_BASEURL,
  frasierDomain: VITE_APP_FRASIER_DOMAIN,
  sentryDsn: VITE_APP_SENTRY_DSN,
  ssbtStompUrl: VITE_APP_SSBT_STOMP_URL
}
