import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import useNotificationsStore from '@/stores/notifications'
import authApi from '@/api/auth'
import { ERROR_CODES } from '@/utilities/constants'
import { getErrorTranslation, extractErrorCode } from '@/utilities/error'
import {
  type GenerateQRCodeResponse,
  type VerifyOTPParams,
  type VerifyOTPResponse,
  type CashoutParams
} from '@/types/api/auth'
import config from '@/config'

const useAuthStore = defineStore('auth', () => {
  const notificationsStore = useNotificationsStore()

  const { handleErrorNotification } = notificationsStore

  const qrCodeLinkData = ref<GenerateQRCodeResponse | null>(null)
  const authData = ref<VerifyOTPResponse | null>(null)
  const isTerminalOutOfOrder = ref(true)
  const isCashoutLoading = ref(false)

  const isAuthenticated = computed(() => Boolean(authData.value?.jwt && authData.value.sessionId))

  const generateQRCode = async () => {
    try {
      const payload = { dongleId: config.dongleId }
      qrCodeLinkData.value = await authApi.generateQRCode({ payload })
      isTerminalOutOfOrder.value = false
    } catch (err) {
      handleErrorNotification(err)
      qrCodeLinkData.value = null
      isTerminalOutOfOrder.value = true

      return {
        isError: true
      }
    }
  }

  const verifyOTP = async (params: VerifyOTPParams) => {
    try {
      authData.value = await authApi.verifyOTP(params)
    } catch (err) {
      const nonFatalErrors: ERROR_CODES[] = [ERROR_CODES.OTP_EXPIRED, ERROR_CODES.OTP_INCORRECT]
      const code = extractErrorCode(err)
      const isFatal = code === null || !nonFatalErrors.includes(code)

      if (isFatal) {
        handleErrorNotification(err)
      }

      return {
        isError: true,
        isFatal,
        errorMessage: getErrorTranslation(err)
      }
    }
  }

  const cashout = async (params: CashoutParams) => {
    try {
      isCashoutLoading.value = true
      await authApi.cashout(params)
    } catch (err) {
      handleErrorNotification(err)
    }
  }

  return {
    qrCodeLinkData,
    authData,
    isTerminalOutOfOrder,
    isAuthenticated,
    isCashoutLoading,
    generateQRCode,
    verifyOTP,
    cashout
  }
})

export default useAuthStore
