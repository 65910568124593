<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import { RouterView } from 'vue-router'
import { storeToRefs } from 'pinia'
import { SCToastNotificationGroup } from '@nsftx/seven-components'

import { useEventMonitor } from '@/composables/useEventMonitor'
import useNotificationsStore from '@/stores/notifications'
import useGravityGatewayStore from '@/stores/gravityGateway'
import useTranslationsStore from '@/stores/translations'

const { start: startEventMonitor } = useEventMonitor()
const notificationsStore = useNotificationsStore()
const gravityGatewayStore = useGravityGatewayStore()
const translationsStore = useTranslationsStore()

const { notifications } = storeToRefs(notificationsStore)

const { init: initGravityGateway } = gravityGatewayStore
const { getTranslations } = translationsStore

const isAppLoading = ref(false)

onBeforeMount(async () => {
  startEventMonitor()

  isAppLoading.value = true
  initGravityGateway()
  await getTranslations()
  isAppLoading.value = false
})
</script>

<template>
  <RouterView v-if="!isAppLoading" />

  <SCToastNotificationGroup v-model="notifications" position="bottom" />
</template>
