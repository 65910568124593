export enum STOMP_CLIENT_MESSAGES {
  SESSION_TERMINATED = 'SESSION_TERMINATED',
  SESSION_INITIATED = 'SESSION_INITIATED',
  TERMINAL_DEACTIVATED = 'TERMINAL_DEACTIVATED',
  SUCCESSFUL_DEPOSIT = 'SUCCESSFUL_DEPOSIT'
}

type BaseSocketMessage = {
  sessionId: string
  timestamp: string
}

type BaseSessionMessage = {
  type:
    | STOMP_CLIENT_MESSAGES.SESSION_TERMINATED
    | STOMP_CLIENT_MESSAGES.SESSION_INITIATED
    | STOMP_CLIENT_MESSAGES.TERMINAL_DEACTIVATED
  message: BaseSocketMessage
}

type SuccessfulDepositMessage = {
  type: STOMP_CLIENT_MESSAGES.SUCCESSFUL_DEPOSIT
  message: BaseSocketMessage & {
    amount: number
  }
}

export type StompMessageData = BaseSessionMessage | SuccessfulDepositMessage
