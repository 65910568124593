import { createAxiosInstance } from '@/api/http'
import env from '@/config/env'
import {
  type GenerateQRCodeParams,
  type GenerateQRCodeResponse,
  type VerifyOTPParams,
  type VerifyOTPResponse,
  type CashoutParams
} from '@/types/api/auth'

const http = createAxiosInstance({
  baseURL: env.apiBaseUrl
})

const generateQRCode = async (params: GenerateQRCodeParams): Promise<GenerateQRCodeResponse> => {
  const { payload } = params
  const url = 'api/v1/session/generate-qr-code'

  const { data } = await http.post(url, payload)
  return data
}

const verifyOTP = async (params: VerifyOTPParams): Promise<VerifyOTPResponse> => {
  const { payload, sessionId } = params
  const url = `api/v1/session/${sessionId}/verify`

  const { data } = await http.post(url, payload)
  return data
}

const cashout = async (params: CashoutParams) => {
  const { sessionId } = params
  const url = `api/v1/session/${sessionId}/cashout`

  const { data } = await http.post(url)
  return data
}

export default { generateQRCode, verifyOTP, cashout }
