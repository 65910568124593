import { ref } from 'vue'
import { defineStore } from 'pinia'

import useNotificationsStore from '@/stores/notifications'
import userHttp from '@/api/user'
import { type BalanceResponse, type UserData } from '@/types/api/user'

const useUserStore = defineStore('user', () => {
  const notificationsStore = useNotificationsStore()

  const { handleErrorNotification } = notificationsStore

  const userData = ref<UserData | null>(null)
  const balanceData = ref<BalanceResponse>()
  const isCasinoGameOpen = ref(false)

  const getUser = async () => {
    try {
      const response = await userHttp.getUser()
      const { currency, balance } = balanceData.value ?? {}

      userData.value = {
        ...response,
        currency: currency ?? null,
        balance: balance ?? null
      }
    } catch (err) {
      handleErrorNotification(err)
    }
  }

  const getBalance = async () => {
    try {
      const response = await userHttp.getBalance()
      balanceData.value = response
      if (userData.value) {
        const { currency, balance } = response
        userData.value = Object.assign(userData.value, {
          currency,
          balance
        })
      }
    } catch (err) {
      handleErrorNotification(err)
    }
  }

  return {
    userData,
    balanceData,
    isCasinoGameOpen,
    getUser,
    getBalance
  }
})

export default useUserStore
