import env from '@/config/env'
import { type UserResponse, type BalanceResponse } from '@/types/api/user'
import { createAxiosInstance } from '@/api/http'
import { HEADERS } from '@/utilities/constants'

const http = createAxiosInstance(
  {
    baseURL: env.sevenPlatformMonolith
  },
  [HEADERS.CompanyUUID, HEADERS.Authorization]
)

const getUser = async (): Promise<UserResponse> => {
  const url = 'web/profile/register.json'

  const { data } = await http.get(url)
  return data
}

const getBalance = async (): Promise<BalanceResponse> => {
  const url = 'web/profile/balance.json'

  const { data } = await http.get(url)
  return data
}

export default { getUser, getBalance }
