import { type App } from 'vue'
import * as Sentry from '@sentry/vue'
import { type SeverityLevel } from '@sentry/vue'

import env from '@/config/env'

const MESSAGE_SEVERITY_LEVEL: { [K in SeverityLevel]: K } = {
  debug: 'debug',
  info: 'info',
  log: 'log',
  warning: 'warning',
  error: 'error',
  fatal: 'fatal'
}

const trackMessageWithContext = (
  message: string,
  severityLevel: SeverityLevel = MESSAGE_SEVERITY_LEVEL.log,
  user = {
    id: '',
    nickname: ''
  }
) => {
  Sentry.withScope((scope) => {
    scope.setUser({
      id: user.id,
      username: user.nickname
    })
    scope.setLevel(severityLevel)
    Sentry.captureMessage(message)
  })
}

const init = (app: App) => {
  Sentry.init({
    app,
    dsn: env.sentryDsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      env.apiBaseUrl,
      env.sevenPlatformMonolith,
      env.frasierApiBaseUrl,
      env.ssbtStompUrl,
      /^\//
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

export { MESSAGE_SEVERITY_LEVEL, trackMessageWithContext }
export default init
