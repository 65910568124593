const initURLWithParams = (url: string, params: Record<string, string>): string => {
  const urlObj = new URL(url)
  Object.entries(params).forEach(([key, val]) =>
    urlObj.searchParams.set(key, encodeURIComponent(val))
  )

  return urlObj.toString()
}

export { initURLWithParams }
