import axios, { AxiosError } from 'axios'

import useTranslationsStore from '@/stores/translations'
import { ERROR_CODES } from '@/utilities/constants'
import { type TranslationKeys } from '@/types/stores/translations'

type ResponseError = {
  errorSource: string
  httpCode: number
  code: number
  message: string
  errors?: {
    [key: string]: unknown
  }
}

const getTranslationByErrorCode = (code: ERROR_CODES | null) => {
  const translationsStore = useTranslationsStore()
  const { t } = translationsStore

  const errorCodeTranslationsMapper: Record<ERROR_CODES, TranslationKeys> = {
    [ERROR_CODES.OTP_INCORRECT]: 'verify_error_wrong_otp_code',
    [ERROR_CODES.OTP_EXPIRED]: 'verify_error_otp_expired'
  }

  const translationKey = (code && errorCodeTranslationsMapper[code]) || 'default_error_message'

  return t(translationKey)
}

function extractErrorCode(err: unknown) {
  if (axios.isAxiosError(err)) {
    const axiosError = err as AxiosError<ResponseError>

    if (axiosError.response && axiosError.response.data) {
      return axiosError.response.data.code ?? null
    }
  }

  return null
}

const getErrorTranslation = (err: unknown) => {
  const errorCode = extractErrorCode(err)
  return getTranslationByErrorCode(errorCode)
}

export { getTranslationByErrorCode, extractErrorCode, getErrorTranslation }
