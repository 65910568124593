import { useEventListener } from '@vueuse/core'

export function useEventMonitor() {
  const events = ['keydown', 'keyup', 'keypress']

  const handleEvent = (event: KeyboardEvent) => {
    console.log(event)
  }

  const start = () => {
    events.forEach((eventType) => {
      useEventListener(document, eventType, handleEvent, { capture: true })
    })
  }

  return {
    start
  }
}
