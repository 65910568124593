import { type TranslationsResponse } from '@/types/api/translations'
import env from '@/config/env'
import { createAxiosInstance } from '@/api/http'

const http = createAxiosInstance({
  baseURL: env.frasierApiBaseUrl
})

const getTranslations = async (language: string): Promise<TranslationsResponse> => {
  const url = `translations/${env.frasierDomain}/locales/${language}`

  const { data } = await http.get(url)
  return data
}

export default { getTranslations }
