export enum SLAVE_EVENT {
  GameOpen = 'Game.Open',
  GameClose = 'Game.Close',
  'click' = 'click'
}

export enum GATEWAY_EVENT {
  SlaveEvent = 'Slave.Event',
  AnalyticsSend = 'Analytics.Send',
  DocumentChangeMeta = 'Document.ChangeMeta',
  DocumentChangeTitle = 'Document.ChangeTitle',
  RouterRouteChanged = 'Router.RouteChanged',
  UserLoginRequired = 'User.LoginRequired'
}

export enum GATEWAY_EMIT_EVENT {
  UserAuthorizationChanged = 'User.AuthorizationChanged',
  UserBalanceChanged = 'User.BalanceChanged'
}

type BaseSlaveEvent = {
  action: string
  event: string
  msgSender: string
  plugin: string
  slaveId: string
}

type ClickEvent = BaseSlaveEvent & {
  event: SLAVE_EVENT.click
}

type GameEvent = BaseSlaveEvent & {
  event: SLAVE_EVENT.GameOpen | SLAVE_EVENT.GameClose
  data: {
    game: Game
  }
}

type Game = {
  code: string
  demo: boolean
  id: number
  isLive: boolean
  isVaixEvent: boolean
  page: string
  position: number
  positionDisplayId: string
  providerName: string
  swimLane: string
  title: string
}

export type SlaveEventMessage = ClickEvent | GameEvent
